<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">专题管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">专题列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a"
            >{{ stu != "edit" ? "新增" : "修改"
            }}专题</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <div class="form-box">
              <el-form
                ref="ruleForm"
                label-width="7.5rem"
                class="form"
                :rules="rules"
                :model="ruleForm"
              >
                <el-form-item label="专题名称：" prop="specialSubjectName">
                  <el-input
                    v-model="ruleForm.specialSubjectName"
                    size="small"
                    maxlength="50"
                    show-word-limit
                  ></el-input>
                </el-form-item>
               
                <el-form-item
                  label="专题详情："
                  prop="specialSubjectDetail"
                  class="form-item"
                >
                  <div
                    ref="editor"
                    class="editor"
                    style="width:100%;max-width:1200px"
                  />
                </el-form-item>
                <el-form-item label="关联机构：" prop="compId">
                  <el-select
                    size="small"
                    v-model="ruleForm.compId"
                    remote
                    :remote-method="getCompanyList"
                    filterable
                    clearable
                    placeholder="请至少输入两个字搜索"
                  >
                    <el-option
                      v-for="item in CompanyList"
                      :key="item.compId"
                      :label="item.compName"
                      :value="item.compId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="专题课程：" prop="courseIds">
                  <el-select
                    size="small"
                    v-model="ruleForm.courseIds"
                    multiple
                    filterable
                    clearable
                    placeholder="请选择专题课程"
                  >
                    <el-option
                      v-for="item in SpecialCoursesList"
                      :key="item.courseId"
                      :label="item.courseName"
                      :value="item.courseId"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
            <div class="btn-box flexcc">
              <el-button @click="dohandleOk" class="bgc-bv">取 消</el-button>
              <el-button
                class="bgc-bv"
                @click="doAddSave('ruleForm');
                "
                >保存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <MenuStyle ref="MenuStyle" @eventBus="styleBack" /> -->
  </div>
</template>
<script>
import E from "wangeditor";
let editor = {};
export default {
  name: "operate/accountEdit",
  components: {},
  data() {
    return {
      stu: "add",
      // 基本信息
      ruleForm: {
        specialSubjectName: "",
        context: "", // 富文本
        compId: "",
        courseIds:[]
      },
      rules: {
        specialSubjectName: [
          { required: true, message: "请输入专题名称", trigger: "blur" },
        ],
        context: [
          { required: true, message: "请输入专题详情", trigger: "change" },
        ],
        compId: [
          { required: true, message: "请选择关联机构", trigger: "change" },
        ],
        courseIds: [
          { required: true, message: "请选择专题课程", trigger: "change" },
        ],
      },
      CompanyList: [],
      SpecialCoursesList:[], //专题课程
    };
  },
  created() {
    this.stu = this.$route.query.stu;
    this.getSpecialCoursesList()
    if (this.stu == "edit") {
      this.ruleForm.specialSubjectId = this.$route.query.specialSubjectId;
      this.getDetail(this.ruleForm.specialSubjectId);
    }
  },
  mounted() {
    this.editorInit();
    const eWidth = this.$refs.editor.clientWidth;
    this.eWidth = eWidth;
  },
  computed: {},
  methods: {
    // 富文本
    editorInit() {
      editor = new E(this.$refs.editor);
      editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "undo", // 撤销
        "redo", // 重复
      ];
      editor.config.zIndex = 100;
      editor.config.uploadImgShowBase64 = true;
      editor.config.uploadImgMaxSize = 1 * 1024 * 1024;
      editor.config.pasteFilterStyle = "text";
      editor.config.pasteIgnoreImg = true;

      editor.config.onchange = (html) => {
        this.ruleForm.specialSubjectDetail = html;
      };
      editor.config.pasteTextHandle = function(content) {
        return content + "<p></p>";
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
      };
      editor.create();
      const eWidth = this.$refs.editor.clientWidth;
      this.eWidth = eWidth;
    },

    /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        specialSubjectName: this.ruleForm.specialSubjectName,
        specialSubjectDetail: this.ruleForm.specialSubjectDetail,
        compId: this.ruleForm.compId,
        courseIds:this.ruleForm.courseIds,
      };
      if(this.stu == "edit") {
          params.specialSubjectId = this.ruleForm.specialSubjectId
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit" ? "/miniapp/special/subject/insert" : "/miniapp/special/subject/modify",
            params,
            3000,
            true,
            2
          )
            .then((res) => {
              if (res.status == "0") {
                if (this.stu != "edit") {
                  this.$message({
                    type: "success",
                    message: "新增成功",
                  });
                } else {
                  this.$message({
                    type: "success",
                    message: "修改成功",
                  });
                }
                this.dohandleOk();
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    /**
     * 编辑回显
     */
    getDetail(specialSubjectId) {
      this.$post(
        "/miniapp/special/subject/getInfo",
        { specialSubjectId },
        3000,
        true,
        2
      ).then((ret) => {
        this.ruleForm = {
          ...ret.data
        };
        editor.txt.html(ret.data.specialSubjectDetail);
        this.getCompany(ret.data.compId);
        }
      );
    },

  
    // 关联单位
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 专题课程
    getSpecialCoursesList(query) {
        this.$post("/miniapp/special/subject/coursePageList", {},3000,true,2)
          .then((res) => {
            if (res.status == 0) {
              this.SpecialCoursesList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
    },
    /**
     * 单位回显接口
     */
    getCompany(id) {
      this.$post("/sys/company/id", { compId: id }).then((res) => {
        this.CompanyList = [res.data];
      });
    },
    /**
     * 返回
     */
    dohandleOk() {
      setTimeout(() => {
        this.$router.push({
          path: "/web/miniApp/thematicList",
          query: {
            refresh: true,
          },
        });
      }, 300);
    },
  },
};
</script>
<style lang="less" scoped>
.andDiscountPrice {
  /deep/.el-form-item__content {
    margin-left: 0 !important;
  }
}
.price {
  display: flex;
  //   width: 45%;
  div {
    display: flex;
    span {
      text-align: right;
      margin-right: 0.25rem;
    }
  }
}
.createdCourse {
  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 240px;
    font-size: 14px;
  }
  .level {
    padding: 3px;
    border-radius: 6px;
    color: #333;
    background-color: #e0f2ff;
    min-width: 20px;
    text-align: center;
  }
}

.formCom {
  h3 {
    padding: 15px 0;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .chapterl-btns {
    .el-button {
      padding: 8px;
    }
  }
  .el-cascader {
    width: 100%;
  }
  .el-progress {
    width: 200px;
    padding-left: 15px;
  }
  .chapterl-trees {
    padding: 15px 10px 15px 0;
    .el-tree-node__content {
      height: 36px;
      margin-bottom: 15px;
    }
    .draggable-item {
      margin-left: 10px;
      em {
        margin-right: 5px;
      }
    }

    .trees-btns {
      display: flex;
      align-items: center;
      .showbtn {
        color: #fff;
      }
      margin-left: 15px;
      & > em,
      .handle > em {
        color: #4089fa;
        font-size: 14px;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .chapteru-lists {
    padding: 15px 15px 15px 0;
    .el-divider--horizontal {
      margin: 5px;
    }
    .cl-subhint {
      display: flex;
      span {
        font-size: 14px;
        color: #666;
        &:nth-child(2) {
          margin-left: 15px;
        }
      }
      em {
        margin: 0 5px;
      }
    }
    .cl-btns {
      display: flex;
      align-items: center;
      width: 4rem;
      justify-content: space-between;
      i {
        color: #999999;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }
  .el-icon-receiving {
    width: 42px;
    height: 42px;
    font-size: 40px;
    color: #999;
    margin-right: 15px;
  }
  .pageForm-btns {
    padding: 15px 0;
    text-align: center;
    .el-button {
      width: 10rem;
      span {
        min-width: 4em;
      }
    }
  }
  .file-list-group {
    .list-group-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .tree-drag-group {
    display: flex;
    align-items: center;
    font-size: 14px;
    .list-group-item {
      margin-left: 5px;
      display: inline-block;
      .el-icon-receiving {
        font-size: 22px;
        color: #2d2;
      }
      .df {
        display: none;
      }
    }
  }
  .bgc-height {
    background-color: #f4ff00;
  }
  .el-upload-list--picture-card .el-upload-list__item {
    width: 148px !important;
    height: 148px !important;
  }
  .hide /deep/.el-upload--picture-card {
    display: none;
  }
}

.btnbox {
  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}
.courseDescription {
  /deep/.el-textarea__inner {
    min-height: 120px !important;
    resize: none;
  }
}
</style>
<style lang="less" scoped>
.el-textarea {
  .el-textarea__inner {
    min-height: 10px !important;
    height: 100px !important;
    resize: none;
  }
}

.courseIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/courseIcon.png") no-repeat;
  background-size: cover;
}

.paperIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  display: inline-block;
  background: url("../../../assets/paperIcon.png") no-repeat;
  background-size: cover;
}

.nullIcon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}
</style>
